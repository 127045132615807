@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Petit+Formal+Script&family=Work+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Open Sans", sans-serif;
  color: #555;
}
h1 {
  font-family: "Abel", sans-serif;
}
.mb20 {
  margin-bottom: 20px;
}
.notification-heading {
  font-family: "Work Sans", sans-serif;
}
.policy-icon {
  width: 200px !important;
  height: 200px !important;
  color: teal;
  fill: #008080;
}
a {
  font-family: "", sans-serif;
}
footer {
  background: teal;
  color: white;
  padding: 20px;
}
footer a {
  color: white;
  margin-left: 12px;
}
.finance-info-container {
  width: 100%;
  position: relative;
  height: 150px;
  border: 1px solid #0080803a;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px;
  box-shadow: -1px -1px 1px 2px #008080;
}
.info-container {
  height: 100%;
  aspect-ratio: 1/1;
  border: 1px solid #0080803a;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  box-shadow: 1px 1px 1px 2px teal;
}
.full-height {
  height: calc(100vh - 100px) !important;
}
.bar-chart-container {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borrowed-table-container,
.borrowing-chart-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 100%;
}
.exchange-container {
  width: 100%;
  display: flex;
  align-content: inherit;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #ffffffb1;
}
.exchange-img {
  width: 40%;
}
.borrowing-chart-container {
  overflow-x: scroll;
}
.intro-nav-btn {
  text-decoration: none;
  cursor: pointer;
  border: 2px solid teal;
  background: #fff;
  color: teal;
  height: 35px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.intro-nav-btn:last-child {
  background: teal;
  color: white;
}
.highlight-title {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 0.8rem;
  margin-bottom: 15px !important;
}
.highlight-subtitle {
  font-size: 0.8rem;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.piechart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.finance-info-key {
  font-size: 0.8em;
  color: #6d7171;
}
.finance-info-value {
  color: teal;
  font-weight: 600;
  font-size: 0.8rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.main-container {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background: #10b09b43;
  position: relative;
}
::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background: #1d718432;
}
.topbar-container {
  width: 100%;
  position: sticky;
  top: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background: teal;
  z-index: 9999;
}
.notifications {
  height: 2rem;
  width: 2rem;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.notifications-icon {
  fill: white;
  font-size: 20px;
}
.badge {
  height: 10px;
  width: 10px;
  background: white;
  border-radius: 50%;
  border: 2px solid #0d61df;
  position: absolute;
  bottom: -2px;
  right: -2px;
}
.link {
  margin: 0 10px;
  font-size: inherit;
}
.logo-text {
  color: white;
  font-weight: 600;
  font-size: 1.6rem;
  text-decoration: none;
  font-family: "Petit Formal Script", cursive;
  display: flex;
  align-items: center;
  gap: 10px;
}
.top-bar-logo {
  height: 35px;
  width: 35px;
}
.no-data-text {
  font-weight: 300;
  margin: 20px;
  font-size: 1rem;
}
.menu-icon {
  fill: white !important;
  font-size: 2rem !important;
  cursor: pointer;
}
.topbar-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.topbar-btn {
  border: 2px solid white;
  background: transparent;
  height: 35px;
  padding: 0 6px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.sm-icon {
  display: none;
}
.table-container {
  width: 100% !important;
  background: #edf2f3;
  min-height: 160px;
  overflow: scroll;
}
.debt-text {
  font-weight: 300;
  font-size: 1.4rem;
}
.dialog {
  z-index: 9999999999999999;
}
.dialog-form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.login-input {
  width: 100%;
  background: transparent;
  height: 35px;
  min-width: 350px;
  border-radius: 5px;
  outline: 1.5px solid teal;
  padding: 5px 10px;
  border: 1px solid lightgrey;
}
.dialog-close-btn {
  height: 35px;
  border: 2px solid teal;
  color: teal;
  background: white;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}
.dialog-label {
  color: #555;
}
.side-menu-container {
  width: 90%;
  max-width: 350px;
  position: fixed;
  top: 60px;
  right: 0;
  background: #4fb0b0;
  z-index: 12229999999;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}
.menu-item {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #bbb9b9;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.portfolio-action-container {
  display: flex;
  align-items: center;
  gap: 3px;
}
.portfolio-action-icon {
  cursor: pointer;
  justify-self: center;
  font-size: 0.8em !important;
}
.achieved {
  fill: #ff0077 !important;
  font-size: 0.6rem !important;
}
.red {
  color: red;
}
.loading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: absolute;
  top: 50%;
}

.portfolio-info-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 400px;
}
.info-key,
.dialog-icon {
  font-size: 0.8rem;
  font-weight: 200;
}
.info-key {
  display: flex;
  align-items: center;
  gap: 6px;
}

.progress-container {
  height: 6px;
  width: 100%;
  background: #bde5ef;
}
.progress {
  height: inherit;
  background: #01a3d0;
  max-width: 100%;
}
.key-value-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  width: 100%;
}
.subscribe-btn {
  background: #3399ff;
  border: none;
  padding: 10px;
  /* font-size: 1rem; */
  font-weight: 500;
  cursor: pointer;
  color: white;
  border-radius: 5px;
}
.subscribe-btn:last-child {
  background: #ff33b1;
}
/* go premium */
.go-premium-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  margin: 20px 0;
}
.go-premium-title {
  font-size: 1rem;
  text-align: center;
}
.go-premium-message {
  line-height: 1.6rem;
}
.add-expenses-btn-container {
  position: relative;
  bottom: 15px;
  left: 50%;
  height: 4rem;
  width: 4rem;
  background: #ffffff82;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  border: 2px solid teal;
  box-shadow: 2px 1px 7px 1px rgba(0, 128, 128, 0.83);
  -webkit-box-shadow: 2px 1px 7px 1px rgba(0, 128, 128, 0.83);
  -moz-box-shadow: 2px 1px 7px 1px rgba(0, 128, 128, 0.83);
}
.action-container {
  position: fixed;
  bottom: 15px;
  left: 50%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
}
.loan-action-container {
  gap: 15px;
  transform: translateX(-80%) !important;
}
.add-expenses-btn-container:focus {
  outline: none;
  background: none;
}
.add-expenses-btn {
  font-size: 2rem;
  fill: teal;
}
.overlay {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: rgba(42, 51, 54, 0.948);
  z-index: 99;
  padding: 20px;
}
.overlay-text {
  color: white;
  text-align: center;
}
.overlay-action-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.chart-filter {
  padding: 2px 5px;
  border: none;
  border-radius: 4px;
  background-color: thistle;
  cursor: pointer;
}
.chart-filter:focus {
  background: white;
}
.chart-filter-container {
  display: flex;
  gap: 6px;
}
.chart-container {
  width: 100%;
  height: 500px;
}
.radial-chart-container {
  background: #fff !important;
  border-radius: 5px;
  color: #acb4b9;
}
@media (max-width: 700px) {
  .chart-container {
    overflow-x: scroll;
  }
  .exchange-container {
    flex-direction: column;
  }
  .exchange-img {
    width: 80%;
  }
}
@media (max-width: 426px) {
  .topbar-container {
    padding: 0 10px;
  }
  .logo-text {
    font-size: 1.2rem;
  }
  .top-bar-logo {
    height: 25px;
    width: 25px;
  }
  .topbar-btn {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .portfolio-info-container {
    width: 100%;
    min-width: 80vw;
  }
  .login-input {
    min-width: 200px !important;
    width: 100%;
  }
}
@media (max-width: 400px) {
  .finance-info-container {
    padding: 10px;
    gap: 6px;
  }
  .info-container {
    height: 85%;
  }
  .sm-icon {
    display: inline-block;
  }
  .topbar-btn {
    display: none;
  }
}
@media (max-width: 375px) {
  .topbar-left {
    gap: 5px;
  }

  .finance-info-container {
    height: 150px;
  }
  .info-container {
    height: 78%;
  }
  .finance-info-key,
  .finance-info-value {
    font-size: 0.6rem;
  }
}
@media (max-width: 340px) {
  .info-container {
    height: 70%;
  }
  .intro-nav-btn1 {
    font-size: 0.6rem !important;
    height: 35px !important;
    border-radius: 4px !important;
  }
  .nav-btn-icon {
    font-size: 0.6rem !important;
  }
  .intro-nav-btn {
    padding: 0 4px;
  }
}

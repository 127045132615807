.login-container {
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;
}
.login-sub-container {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffffc1;
  box-shadow: 0px 0px 11px 0px teal;
  border-radius: 10px;
  padding: 15px;
}

.login-icon {
  font-size: 15rem !important;
  fill: teal !important;
}
.login-logo {
  height: 300px;
}
.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  max-width: 450px;
  gap: 15px;
}
.login-text {
  font-weight: 300;
  font-size: 1.2rem;
}
.login-input {
  width: 100%;
  background: transparent;
  height: 35px;
  min-width: 350px;
  border-radius: 5px;
  outline: 1.5px solid teal;
  padding: 5px 10px;
  border: 1px solid lightgrey;
}
.login-btn {
  width: 100%;
  height: 35px;
  border: none;
  background: teal;
  color: white;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}
.login-redirects {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media (max-width: 670px) {
  .login-sub-container {
    flex-direction: column;
  }
  .login-icon {
    font-size: 8rem !important;
  }
}
@media (max-width: 415px) {
  .login-input {
    min-width: 200px;
  }
  .login-logo {
    height: 220px;
  }
  .login-redirects {
    flex-direction: column;
  }
}
